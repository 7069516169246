.gallery {
  border-bottom: 2px dotted rgba($primary, 0.2);
  padding: 20px 0 20px 0;
  img {
    width: 144px;
    height: 114px;
    object-fit: cover;
    margin-right: 8px;
    margin-bottom: 8px;
    border-radius: 4px;
    padding: 2px;
    box-shadow: 0 0 4px $gray-500;
    &:hover {
      box-shadow: 0 0 4px $gray-700;
    }
  }
}

@media screen and (max-width: 767px) {
  .gallery {
    margin-bottom: 30px;
    img {
      width: 100px;
      height: 80px;
    }
  }
}
