.news {
  a {
    text-decoration: none;
    .card {
      &:hover {
        box-shadow: 0px 0px 12px $gray-400;
      }
      margin-bottom: 30px;
      border-color: $gray-200;
      text-decoration: none;
      color: $gray-800;
      .card-image {
        width: 100%;
        height: 200px;
        object-fit: cover;
      }
      .card-date {
        font-size: 0.9rem;
        color: $gray-600;
      }
      .card-title {
        //color: $primary;
        text-transform: uppercase;
        margin-top: 6px;
        margin-bottom: 6px;
      }
      .card-text {
        margin-bottom: 4px;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .news {
    margin-bottom: 80px;
  }
}
