.resources {
  //background-color: $gray-200;
  border-top: 2px dotted rgba($primary, 0.2);
  h4 {
    font-size: 1.2rem;
    text-transform: uppercase;
    font-weight: 400;
    margin-bottom: 20px;
    letter-spacing: 0.08rem;
  }
}
