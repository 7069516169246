aside {
  h5 {
    font-weight: 300;
    margin-top: 30px;
    margin-bottom: 15px;
  }
  .card {
    margin-bottom: 20px;
    position: relative;
    .card-img-top {
      img {
        width: 100%;
        height: 300px;
        object-fit: cover;
      }
    }
    .card-body {
      position: absolute;
      width: 100%;
      height: 100%;

      display: flex;
      align-items: center;
      justify-content: center;

      .card-title {
        text-transform: uppercase;
        text-align: center;
        color: white;
        font-weight: 300;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  aside {
    .card {
      margin-bottom: 15px;
    }
  }
}
