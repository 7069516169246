h1,
h2,
h3,
h4,
h5,
h6 {
  //font-weight: 400;
  letter-spacing: 0.02rem;
}

p {
  //font-size: 1.04rem;
}

.lead {
  font-size: 1.34rem;
  margin-bottom: 30px;
  // font-weight: 400;
}

a {
  color: $primary;

  &:hover {
    color: darken($primary, 10%);
  }
}
