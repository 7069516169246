// ejemplos implementación bootstrap 5
// https://www.codeply.com/p/IdzoX6osI2
@import './custom';
@import '~bootstrap';

// template
@import './components/types';
@import './components/layout';
@import './components/navbar';
@import './components/footer';
@import './components/aside';

// components
@import './components/card';
@import './components/carousel';
@import './components/news';
@import './components/detail';
@import './components/resources';
@import './components/download';
@import './components/gallery';
