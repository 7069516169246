.carousel {
  .carousel-inner {
    .carousel-item {
      img {
        width: 100%;
        height: 300px;
        object-fit: cover;
      }
      .carousel-caption {
        // background-color: orange;
        bottom: 10%;
        height: 80%;

        display: flex;
        align-items: center;
        justify-content: center;

        .caption-title {
          text-transform: uppercase;
          font-weight: 300;
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .carousel {
    .carousel-inner {
      .carousel-item {
        img {
          height: 100px;
        }
      }
    }
  }
}
