.card-post {
  text-decoration: none;
  color: #333;
  margin-bottom: 30px;
  &:hover {
    text-decoration: none;
    color: #333;
    box-shadow: 0 0 12px $gray-300;
  }
  .card-image {
    background-color: rgba($primary, 0.1);
    border: none;
    img {
      width: 100%;
      height: 240px;
      object-fit: cover;
    }
  }
  .card-body {
    min-height: 180px;
    height: 180px;
    .card-title {
      color: $primary;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    .card-text {
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
}
