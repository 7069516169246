@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed&display=swap');

// Change the primary colour
$primary: #60aa85;

// Set the sans-serif font
// $font-family-sans-serif: 'Arial', sans-serif;

// Maybe set a difference heading font family
// $headings-font-family: Georgia, serif;

// Make headings bolder!
// $headings-font-weight: 700;

// Thicken up our borders
// $border-width: 3px;

// Remove the border-radius everywhere
$border-radius: 0;
