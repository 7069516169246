.navbar {
  font-family: 'Roboto Condensed', sans-serif;
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: darken($primary, 0%);
  .navbar-brand {
    img {
      height: 80px;
    }
  }
  .navbar-toggler {
    border: 0px;
  }
  .navbar-nav {
    .nav-item {
      .nav-link {
        letter-spacing: -0.02rem;
        text-transform: uppercase;
        font-size: 0.94rem;
        color: white;
        &:hover {
          color: $warning;
        }
        &.active {
          //color: red;
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .navbar {
    padding-top: 12px;
    padding-bottom: 12px;
    .navbar-brand {
      img {
        height: 50px;
      }
    }
    .navbar-nav {
      margin-top: 16px;
    }
  }
}
