html {
  min-height: 100vh;
  position: relative;
}

body {
  margin-bottom: 200px;
}

.wrapper {
  padding-top: 80px;
  padding-bottom: 80px;

  .title {
    text-transform: uppercase;
    font-weight: 400;
    margin-bottom: 20px;
    padding-bottom: 16px;
    // border-bottom: 4px solid rgba($primary, 0.08);
    //color: $primary;
    font-size: 2.2rem;
  }
}

@media screen and (max-width: 767px) {
  body {
    margin-bottom: 260px;
  }

  .wrapper {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}
