.detail {
  margin-bottom: 30px;
  .date {
    color: $gray-500;
    margin-bottom: 8px;
    font-size: 0.96rem;
  }
  .cover {
    width: 100%;
    height: 340px;
    object-fit: cover;
    margin-bottom: 40px;
  }

  article {
    margin-bottom: 40px;
    img {
      margin-bottom: 20px;
      box-shadow: 0 0 8px $gray-400;
    }
  }

  h4 {
    font-weight: 400;
    letter-spacing: 0.02rem;
    margin-top: 30px;
    margin-bottom: 15px;
  }
  h5 {
    //margin-top: 30px;
    margin-bottom: 15px;
    a {
      text-decoration: none;
    }
  }

  ul {
    list-style-type: square;
  }
  .complements {
    margin-top: 40px;
    border-top: 2px dotted rgba($primary, 0.2);
    img {
      margin-bottom: 20px;
    }
  }

  iframe {
    width: 100%;
  }
}
