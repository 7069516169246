.download {
  border-bottom: 2px dotted rgba($primary, 0.2);
  padding: 20px 0 20px;
  a {
    text-decoration: none;
  }
}

@media screen and (max-width: 767px) {
  .download {
    margin-bottom: 30px;
  }
}
