footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: $gray-800;
  color: white;
  a {
    text-decoration: none;
  }
  .container {
    height: 200px;
    display: flex;
    align-items: center;
    text-align: center;
    .row {
      width: 100%;
      margin-left: 0px;
      .institutional {
        text-transform: uppercase;
        font-size: 1.6rem;
        font-weight: 600;
        letter-spacing: -0.04rem;
      }
      .contact {
        //color: yellow;
        .btn {
          text-decoration: none;
        }
      }
      .brand-grc {
        img {
          height: 60px;
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    background-color: $gray-800;
    color: white;
    a {
      text-decoration: none;
    }
    .container {
      height: 260px;
      .row {
        .institutional {
          font-size: 1.2rem;
          margin-bottom: 8px;
        }
        .contact {
          //color: yellow;
          margin: 10px 0;
          .btn {
            text-decoration: none;
          }
        }
        .brand-grc {
          img {
            height: 50px;
          }
        }
      }
    }
  }
}
